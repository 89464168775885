import { Carousel } from 'react-carousel-minimal';
import React, {useState, useEffect} from 'react'
import {storage} from "../firebase/firebase";


const Photos = () => {

const allInputs = {imgUrl: ''}
const [imageAsFile, setImageAsFile] = useState('')
const [imageAsUrl, setImageAsUrl] = useState(allInputs)




 const handleImageAsFile = (e) => {
      const image = e.target.files[0]
      setImageAsFile(imageFile => (image))
  }

  

  
  const [files, setFiles] = useState([]);



useEffect(() => {
  const fetchImages = async () => {

  let result = await storage.ref('images').listAll();
      let urlPromises = result.items.map(imageRef => imageRef.getDownloadURL());
  
      return Promise.all(urlPromises);

  }
  
  const loadImages = async () => {
      const urls = await fetchImages();
      setFiles(urls);
      
  }
  loadImages();
  }, []);

  

  const handleFireBaseUpload = e => {
    e.preventDefault()
 
  // async magic goes here...
  if(imageAsFile === '') {
    console.error(`not an image, the image file is a ${typeof(imageAsFile)}`)
  }
  const uploadTask = storage.ref(`/images/${imageAsFile.name}`).put(imageAsFile)
  //initiates the firebase side uploading 
  uploadTask.on('state_changed', 
  (snapShot) => {
    //takes a snap shot of the process as it is happening
    console.log(snapShot)
  }, (err) => {
    //catches the errors
    console.log(err)
  }, () => {
    // gets the functions from storage refences the image storage in firebase by the children
    // gets the download url then sets the image from firebase as the value for the imgUrl key:
    storage.ref('images').child(imageAsFile.name).getDownloadURL()
     .then(fireBaseUrl => {
       setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
     })
  })
  }


    const allImages = files.map((image, index) => {
        return ({image: image})
     })
  
 

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  if (files.length > 0 ){

  
  return (
    <div className="App">
      <div style={{ textAlign: "center" }}>
      <div class="w3-center w3-padding-64">
          <span class="w3-xlarge w3-bottombar w3-border-dark-grey w3-padding-16">Our Work</span>
        </div>
        <p>Here we have a collection of the work we carry out for our clients.</p>
        <div style={{
          padding: "0 20px"
        }}>
          <Carousel
            data={allImages}
            time={2000}
            width="850px"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "40px auto",
            }}
          />
        </div>
      </div>
    </div>
  );}

  else {
      return (
          <p>Loading... </p>
      )
  }
        }

export default Photos;
