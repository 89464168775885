import MainContainer from './containers/MainContainer';

function App() {
  return (
    
    <body>
    <MainContainer />
    </body>
  
  );
}

export default App;
