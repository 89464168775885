import React, {useState, useEffect} from 'react';
import AddPhotos from '../components/AddPhotos';


const AdminPage2 = () => {


const [auth, setAuth] = useState(false);
const [users, setUsers] = useState([]);
const [wrong, setWrong] = useState(null);
const [stateLogin, setStateLogin] = useState(
  {
      username: "",
      password: ""
  }
)







const handleChange = function(event){
  let propertyName = event.target.name;
  let copiedLogin= {...stateLogin}
  copiedLogin[propertyName] = event.target.value;
  setStateLogin(copiedLogin)
}

const handleSubmit = function(event){
  event.preventDefault();
  if ( stateLogin.username === 'admin' && stateLogin.password ===  '##mg??'){
    setAuth(true)
    
  }else {
    setWrong("Incorrect - Try again")
  }
  
  
}





if (auth === true){

  return (

    <AddPhotos />
  )

} else {





  



return (

  <form id="admin-form"onSubmit={handleSubmit}>
        <div>
        <label>Username</label>
        
        </div>
        <div>
        <input id="username" type="text" placeholder="" name="username" onChange={handleChange} value={stateLogin.username} />
        </div>
        <br></br>
           <div>

           <label>Password</label>
         </div>
           <div>
           <input id="password" type="password" placeholder="" name="password" onChange={handleChange} value={stateLogin.password}  />
           </div>
           <br></br>
          <div id="btn-login">
         <button  type="submit">Login</button>
         <div>
           <br></br>
         <label>{wrong}</label>
         </div>
         
         </div>
           
              
           </form>
)

}
  
}

export default AdminPage2;