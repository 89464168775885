//add useState for handling the image as a file and then the image as a url from firebase
import React, {useState, useEffect} from 'react'
import {storage} from "../firebase/firebase";


import { Link, useHistory } from "react-router-dom";
//add import for storage 
function Photo3() {

const allInputs = {imgUrl: ''}
const [imageAsFile, setImageAsFile] = useState('')
const [imageAsUrl, setImageAsUrl] = useState(allInputs)

var date = Date().toLocaleString()




 const handleImageAsFile = (e) => {
      const image = e.target.files[0]
      setImageAsFile(imageFile => (image))
  }

  

  
  const [files, setFiles] = useState([]);
  const [imageUploaded, setImageUploaded] = useState(null)



useEffect(() => {
  const fetchImages = async () => {

  let result = await storage.ref('images').listAll();
      let urlPromises = result.items.map(imageRef => imageRef.getDownloadURL());
  
      return Promise.all(urlPromises);

  }
  
  const loadImages = async () => {
      const urls = await fetchImages();
      setFiles(urls);
  }
  loadImages();
  }, []);

  

  const handleFireBaseUpload = e => {
    e.preventDefault()
  
  // async magic goes here...
  if(imageAsFile === '') {
    
  }
  const uploadTask = storage.ref(`/images/${imageAsFile.name}`).put(imageAsFile)
  //initiates the firebase side uploading 
  uploadTask.on('state_changed', 
  (snapShot) => {
    //takes a snap shot of the process as it is happening
    
  }, (err) => {
    //catches the errors
    console.log(err)
  }, () => {
    // gets the functions from storage refences the image storage in firebase by the children
    // gets the download url then sets the image from firebase as the value for the imgUrl key:
    storage.ref('images').child(imageAsFile.name).getDownloadURL()
     .then(fireBaseUrl => {
       setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
       setImageUploaded(null)
       setImageUploaded(date)
     })
  })
  }

  



 


if (files.length === 0){
  return (
    <div>
<p>Loading...</p>

    </div>
    
  )
}



  return (

        <div className="image-uploader">
        <div id="image-upload-form">
        <p>Upload files here</p>
        
        <form onSubmit={handleFireBaseUpload}>
        <input  type="file" onChange={handleImageAsFile}/>
        <button>Upload</button>
        </form>
        </div>
        
        <p>{imageUploaded}</p>
        <p>Number of photos: {files.length}</p>
        <Link class="w3-button w3-block w3-black" to="/">Return</Link>
        </div>
    )
    

}



export default Photo3;
