import firebase from 'firebase/app'
import 'firebase/storage'

var firebaseConfig = {
    apiKey: "AIzaSyATQ8w5W_YNvfJG0v88EUmyekkLbGZwjdU",
    authDomain: "mg-electrical-images.firebaseapp.com",
    projectId: "mg-electrical-images",
    storageBucket: "mg-electrical-images.appspot.com",
    messagingSenderId: "240908639354",
    appId: "1:240908639354:web:3f9b5f3f38b6785973efc3",
    measurementId: "G-WSWVB03L6B"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
//analytics is optional for this tutoral 
//   firebase.analytics();

  const storage = firebase.storage()

  export  {
    storage, firebase as default
  }