import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';



export default function Ideas() {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_nom4z4k', 'template_o1a0g7e', e.target, 'user_H4Wjl7r6ifIgxo7RUwFPr')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

  return (

    <>
    <div class="w3-center w3-padding-64" id="contact">
        <span class="w3-xlarge w3-bottombar w3-border-dark-grey w3-padding-16">Contact Us</span>
      </div>
    
      <form class="w3-container" onSubmit={sendEmail}>
        <div class="w3-section">
          <label>Name</label>
          <input class="w3-input w3-border w3-hover-border-black"  type="text" name="name" required/>
        </div>
        <div class="w3-section">
          <label>Email</label>
          <input class="w3-input w3-border w3-hover-border-black"  type="text" name="email" required/>
        </div>
        <div class="w3-section">
          <label>Subject</label>
          <input class="w3-input w3-border w3-hover-border-black"  name="subject" required/>
        </div>
        <div class="w3-section">
          <label>Message</label>
          <input class="w3-input w3-border w3-hover-border-black"  name="message" required/>
        </div>
        <button type="submit" class="w3-button w3-block w3-black">Send</button>
      </form></>
    
  
  );
}