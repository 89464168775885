import React, {useEffect, useState} from 'react';
import '../css/Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Logo from '../images/logo.png';
import schuh from '../images/schuh.png';
import bellGroup from '../images/bellgroup.jpeg';
import ibn from '../images/ibn.png';
import plugdin from '../images/plugdin.png'
import facebook from '../images/facebook.png';
import instagram from '../images/instagram.png';
import twitter from '../images/twitter.png';
import Ideas from '../components/Ideas';
import Photos from '../components/Photos';


const Home = () => {



function getWindowDimensions(){
  const { innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height
  }
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}




if (getWindowDimensions().width > 820){

  return(
    <>
    <div class="w3-top">
      <div class="w3-row w3-large w3-light-grey">
        <div class="w3-col s3">
          <a href="#" class="w3-button w3-block">Home</a>
        </div>
        <div class="w3-col s3">
          <a href="#plans" class="w3-button w3-block">Clients</a>
        </div>
        <div class="w3-col s3">
          <a href="#about" class="w3-button w3-block">About</a>
        </div>
        <div class="w3-col s3">
          <a href="#contact" class="w3-button w3-block">Contact</a>
        </div>
      </div>
    </div>
    <div class="w3-content">
    <div id="header-bar">
      <div>
      <img id="logo" src={Logo}></img>
      </div>
      <div class="line">
      <span></span>
      </div>
      <div>
      <a id="mobile" href="tel:07974 463764">
        <div id="mobile-box">
          <div  className="mobile-icon">
          <FontAwesomeIcon icon={faPhone}/> 
          </div>
          <div>
          <p>07974 463764</p>
          </div>
        </div>
      </a> 
    </div>
    <div class="line">
      <span ></span>
      </div>
      <div>
      <a id="email" href="mailto:william@mgelectrical.org.uk">
        <div id="email-box">
          <div  className="email-icon">
          <FontAwesomeIcon icon={faEnvelope}/> 
          </div>
          <div>
          <p>william@mgelectrical.org.uk</p>
          </div>
        </div>
      </a> 
    </div>
    <div class="line">
      <span></span>
      </div>
      <div className="socials">
      <p><a id="link" href="https://www.facebook.com/MGElectrical21"><img  id="facebook"src={facebook}/></a></p>
      <p><a id="link" href="https://twitter.com/MGElectrical1"><img  id="twitter"src={twitter}/></a></p>
      <p><a id="link" href="https://www.instagram.com/mgelectrical1/"><img  id="instagram"src={instagram}/></a></p>
      </div>
      </div>
    <div class="w3-row w3-container">
        <div class="w3-center w3-padding-64">
          <span class="w3-xlarge w3-bottombar w3-border-dark-grey w3-padding-16">What We Do</span>
        </div>
        <div class="w3-col l3 m6 w3-light-grey w3-container w3-padding-16">
          <h3>I.T</h3>
          <ul>
            <li>Data/Comms</li>
            <li>Wi-Fi and Boosters</li>
            <li>POS Installation</li>
            <li>Hardware Installation</li>
          </ul>
        </div>
    
        <div class="w3-col l3 m6 w3-gray w3-container w3-padding-16">
          <h3>CCTV</h3>
          <ul>
            <li>Configured for your needs</li>
            <li>State-of-the-art equipment</li>
            <li>Wireless</li>
            <li>Manage from your mobile</li>
          </ul>
        </div>
    
        <div class="w3-col l3 m6 w3-light-grey w3-container w3-padding-16">
          <h3>Electrical</h3>
          <ul>
            <li>Fuse Boards</li>
            <li>Internal/External Lighting</li>
            <li>Internal/External Sockets</li>
            <li>PAT Testing</li>
          </ul>
        </div>
        <div class="w3-col l3 m6 w3-grey w3-container w3-padding-16">
          <h3>Home Entertainment</h3>
          <ul>
            <li>TV/AV</li>
            <li>Sky/Virgin Box Relocations</li>
            <li>Projectors</li>
            <li>Aerials</li>
          </ul>
        </div>
      </div>
    <div class="w3-container">
      <Photos />
    </div>
    <div class="w3-row-padding" id="plans">
        <div class="w3-center w3-padding-64">
        <span class="w3-xlarge w3-bottombar w3-border-dark-grey w3-padding-16">Our Clients</span>
    </div>
    <div id="clients">
    <div class="w3-third w3-margin-bottom">
      <a href='https://www.bellgroup.co.uk/'><img id="bellgroup" src={bellGroup}></img></a>
    </div>
    <div class="w3-third w3-margin-bottom">
      <a href='https://www.schuh.co.uk/'><img id="schuh" src={schuh}></img></a>
    </div>
    <div class="w3-third w3-margin-bottom">
      <a href='https://www.ibnjoinery.co.uk/'><img id="ibn" src={ibn}></img></a>
    </div>
    <div class="w3-third w3-margin-bottom">
      <a href='https://plugdin.co.uk/'><img id="plugdin" src={plugdin}></img></a>
    </div>
    </div>
    </div>
    <div class="w3-row-padding" id="about">
        <div class="w3-center w3-padding-64">
          <span class="w3-xlarge w3-bottombar w3-border-dark-grey w3-padding-16">Who We Are</span>
        </div>
      <div id="directors">
      <div class="w3-third w3-margin-bottom">
          <div class="w3-card-4">
            <div class="w3-container">
              <h3>William Galloway</h3>
              <p class="w3-opacity">Director</p>
              <p>William has over 15 years experience of working in the IT industry. He is skilled in data cabling, telecoms, and hardware installation. William has been running his own business for over 7 years and has built up an impressive customer base; from small family businesses to large retail chains.</p>
              </div>
          </div>
        </div>
        <div class="w3-third w3-margin-bottom">
          <div class="w3-card-4">
            <div class="w3-container">
              <h3>Stephen Meharry</h3>
              <p class="w3-opacity">Director</p>
              <p>Stephen is a 'Time served' electrician with over 12 years experience. Over this time he has worked in domestic, commercial and industrial projects. He has also spent many years working on the ‘Scottish Power’ network, specialising in internal mains and metering.</p>
              </div>
          </div>
        </div>
        </div>
        </div>
        <Ideas />
      </div>
      <footer class="w3-container w3-padding-32 w3-light-grey w3-center">
      <h4>MG Electrical</h4>
      <h6>2021</h6>
      <p><a href="https://goo.gl/maps/Cbznx4zbBP4phLGU9">Motherwell, Scotland</a></p>
      <a href="#" class="w3-button w3-blue w3-margin"><i class="fa fa-arrow-up w3-margin-right"></i>To the top</a>
      </footer>
    </>
  )}

  else {

  return(
    <>
  <div class="w3-top">
      <div class="w3-row w3-large w3-light-grey">
        <div class="w3-col s3">
          <a href="#" class="w3-button w3-block">Home</a>
        </div>
        <div class="w3-col s3">
          <a href="#plans" class="w3-button w3-block">Clients</a>
        </div>
        <div class="w3-col s3">
          <a href="#about" class="w3-button w3-block">About</a>
        </div>
        <div class="w3-col s3">
          <a href="#contact" class="w3-button w3-block">Contact</a>
        </div>
      </div>
    </div>
    <div class="w3-content">
  <div id="header-bar-mobile">
      <div>
      <img id="logo-mobile" src={Logo}></img>
      </div>
      <div id="contact-icons">
      <a id="mobile-mob" href="tel:07974 463764">
        <div id="mobile-box">
          <div  className="mobile-icon-mob">
          <FontAwesomeIcon icon={faPhone}/> 
          </div>
        </div>
      </a> 
      <a id="email-mob" href="mailto:william@mgelectrical.org.uk">
        <div id="email-box">
          <div  className="email-icon-mob">
          <FontAwesomeIcon icon={faEnvelope}/> 
          </div>
        </div>
      </a> 
    </div>
  </div>
  <div class="w3-row w3-container">
        <div class="w3-center w3-padding-64">
          <span class="w3-xlarge w3-bottombar w3-border-dark-grey w3-padding-16">What We Do</span>
        </div>
        <div class="w3-col l3 m6 w3-light-grey w3-container w3-padding-16">
          <h3>I.T</h3>
          <ul>
            <li>Data/Comms</li>
            <li>Wi-Fi and Boosters</li>
            <li>POS Installation</li>
            <li>Hardware Installation</li>
          </ul>
        </div>
        <div class="w3-col l3 m6 w3-gray w3-container w3-padding-16">
          <h3>CCTV</h3>
          <ul>
            <li>Configured for your needs</li>
            <li>State-of-the-art equipment</li>
            <li>Wireless</li>
            <li>Manage from your mobile</li>
          </ul>
        </div>
    
        <div class="w3-col l3 m6 w3-light-grey w3-container w3-padding-16">
          <h3>Electrical</h3>
          <ul>
            <li>Fuse Boards</li>
            <li>Internal/External Lighting</li>
            <li>Internal/External Sockets</li>
            <li>PAT Testing</li>
          </ul>
        </div>
    
        <div class="w3-col l3 m6 w3-grey w3-container w3-padding-16">
          <h3>Home Entertainment</h3>
          <ul>
            <li>TV/AV</li>
            <li>Sky/Virgin Box Relocations</li>
            <li>Projectors</li>
            <li>Aerials</li>
          </ul>
        </div>
      </div>
      <div>
      <Photos />
      </div>
    <div class="w3-row-padding" id="plans">
    <div class="w3-center w3-padding-64">
      <span class="w3-xlarge w3-bottombar w3-border-dark-grey w3-padding-16">Our Clients</span>
    </div>
    <div id="clients">
    <div class="w3-third w3-margin-bottom">
      <a href='https://www.bellgroup.co.uk/'><img id="bellgroup" src={bellGroup}></img></a>
    </div>
    <div class="w3-third w3-margin-bottom">
      <a href='https://www.schuh.co.uk/'><img id="schuh" src={schuh}></img></a>
    </div>
    <div class="w3-third w3-margin-bottom">
      <a href='https://www.ibnjoinery.co.uk/'><img id="ibn" src={ibn}></img></a>
    </div>
    <div class="w3-third w3-margin-bottom">
      <a href='https://plugdin.co.uk/'><img id="plugdin" src={plugdin}></img></a>
    </div>
    </div>
    </div>
    <div class="w3-row-padding" id="about">
    <div class="w3-center w3-padding-64">
      <span class="w3-xlarge w3-bottombar w3-border-dark-grey w3-padding-16">Who We Are</span>
    </div>
    <div class="w3-third w3-margin-bottom">
          <div class="w3-card-4">
            <div class="w3-container">
              <h3>William Galloway</h3>
              <p class="w3-opacity">Director</p>
              <p>William has over 15 years experience of working in the IT industry. He is skilled in data cabling, telecoms, and hardware installation. William has been running his own business for over 7 years and has built up an impressive customer base; from small family businesses to large retail chains.</p>
              </div>
          </div>
        </div>
    
        <div class="w3-third w3-margin-bottom">
          <div class="w3-card-4">
            <div class="w3-container">
              <h3>Stephen Meharry</h3>
              <p class="w3-opacity">Director</p>
              <p>Stephen is a 'Time served' electrician with over 12 years experience. Over this time he has worked in domestic, commercial and industrial projects. He has also spent many years working on the ‘Scottish Power’ network, specialising in internal mains and metering.</p>
              </div>
          </div>
        </div>
    </div>
    <Ideas />
    </div>
    <footer class="w3-container w3-padding-32 w3-light-grey w3-center">
      <h4>MG Electrical</h4>
      <h6>2021</h6>
      <p><a href="https://goo.gl/maps/Cbznx4zbBP4phLGU9">Motherwell</a></p>
      <div className="socials-mob">
      <p><a id="link" href="https://www.facebook.com/MGElectrical21"><img  id="facebook"src={facebook}/></a></p>
      <p><a id="link" href="https://twitter.com/MGElectrical1"><img  id="twitter"src={twitter}/></a></p>
      <p><a id="link" href="https://www.instagram.com/mgelectrical1/"><img  id="instagram"src={instagram}/></a></p>
      </div>
      <a href="#" class="w3-button w3-blue w3-margin"><i class="fa fa-arrow-up w3-margin-right"></i>To the top</a>
    </footer>
    </>
   )
}
}

export default Home;
