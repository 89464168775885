import React, {Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Helmet } from 'react-helmet'
import Home from '../components/Home';
import AdminPage2 from '../components/AdminPage2';

const MainContainer = () => {



  const TITLE = 'MG Electrical'

    return (
        <Router>
        <Fragment>
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
        
        <div id="routes">
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route path="/reviews" component={ReviewContainer} /> */}
          <Route path="/photos" component={AdminPage2} />
          {/* <Route path="/contact" component={ContactPage} />
          <Route path="/photos" component={Photo3}  /> */}
          {/* <Route path="/admin" component={AdminContainer}  /> */}
          {/* <Route path="/reviewDel" component={AdminPage} /> */}
          {/* <Route path="/newuser" component={NewUser} /> */}
        </Switch>
        </div>
        
        {/* <Footer /> */}
       
  
  
        </Fragment>
        </Router>
      )
}

export default MainContainer;
